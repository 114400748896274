@use '../../styles/shared/include' as *;

.textSliderSection {
	background-color: $brightYellow;
	padding: 0 rem(20);
	.textSlider {
		text-align: center;
		color: $deepBlue;
		padding: rem(24) rem(0);

		p {
			font-size: rem(20);
			font-weight: 500;
			line-height: 140%;
			&:empty {
				display: none;
			}
			u {
				text-decoration: none;
				position: relative;

				&::after {
					content: '';
					width: 100%;
					height: rem(3);
					background-color: $deepBlue;
					position: absolute;
					bottom: rem(0);
					left: 0;
					@media #{$md} {
					bottom: rem(6);
						
					}
				}
			}
		}
		@media #{$md} {
			padding: rem(43) rem(0);

			p {
				font-size: rem(40);
			}
		}
		:global {
			.slick-track {
				display: flex !important;
				align-items: center;
			}
		}
	}
}
