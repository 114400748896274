@use '../../../include' as *;

.header {
	background-color: var(--bw-background);

	@media #{$lgn} {
		border-bottom: rem(1) solid;
	}

	.headerWrapper {
		display: flex;
		align-items: center;
		padding: 20px;
		justify-content: space-between;
		height: rem(72);
		border-bottom: rem(1.5) solid var(--bw-border-soft);

		@media #{$lgn} {
			border-bottom: none;
			padding: 20px;
			max-height: rem(80);
			font-size: rem(18);
		}
	}

	.headerLogo {
		img {
			width: rem(122);
			min-height: rem(25);
			object-fit: fill;
		}

		@media #{$xl} {
			img {
				object-fit: fill;
				width: rem(166);
			}
		}

		@media #{$xxl} {
			img {
				height: auto;
			}
		}
	}

	.navList {
		display: none;

		@media #{$lgn} {
			display: flex;
			align-items: center;
		}
	}

	.navItem {
		position: relative;
		display: block;
		cursor: pointer;
		list-style: none;
		min-width: fit-content;
		font-size: rem(14);
		margin-right: rem(20);
		border-bottom: rem(1) solid transparent;
		transition: border-bottom 0.6s ease;

		svg {
			transition: transform 0.6s ease;
			transform: rotate(0deg);
		}

		@media #{$xl} {
			font-size: rem(16);
		}

		@media #{$xxl} {
			font-size: rem(16);
			margin-right: rem(32);
		}

		&:hover {
			--bw-foreground: var(--bw-text-accent);

			svg {
				path {
					stroke: var(--bw-foreground);
				}
			}
		}

		&--activeMenu {
			--bw-foreground: var(--bw-text-accent);
			transition: border-bottom 0.6s ease;

			svg {
				path {
					stroke: var(--bw-foreground);
				}

				transition: transform 0.6s ease;
				transform: rotate(-180deg);
			}

			&::after {
				content: '';
				position: absolute;
				width: 100% !important;
				height: rem(1);
				bottom: rem(-2);
				left: 0 !important;
				background-color: var(--bw-text-accent);
				transition: width 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
			}

			&:hover::after {
				width: 0 !important;
				right: 0 !important;
				left: auto !important;
			}
		}

		&::after {
			content: '';
			position: absolute;
			width: 0;
			height: rem(1);
			bottom: rem(-2);
			right: 0;
			transition: width 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
		}

		&:hover::after {
			background-color: var(--bw-text-accent);
			left: 0;
			right: auto;
			width: 100%;
		}
	}

	.navContent {
		display: flex;
		gap: rem(4);
		align-items: center;
		min-height: rem(24);
	}

	.hamburgerButton {
		display: flex;
		align-items: center;
		background-color: transparent;

		@media #{$lgn} {
			display: none;
		}
	}

	.desktopCta {
		display: flex;
		gap: rem(24);

		:global {
			button, a {
				font-size: 1rem;

				&:not(.accent) {
					border: none;
				}
			}
		}

		@media #{$lgn-m} {
			display: none;
		}
	}

	.hamburgerIcon {
		position: relative;
		display: flex;
		flex-direction: column;
		justify-content: space-evenly;
		align-items: flex-end;
		width: rem(25);
		height: rem(25);
		transform: rotate(0deg);
		transition: 0.5s ease-in-out;
		cursor: pointer;

		@media #{$lgn} {
			display: none;
		}

		.bar {
			display: block;
			position: absolute;
			height: rem(1.5);
			width: rem(22);
			border-radius: rem(9);
			opacity: 1;
			right: 0;
			transform-origin: left center;
			transform: rotate(0deg);
			transition: 0.25s ease-in-out;
			background-color: var(--bw-foreground);

			&:nth-child(1) {
				top: rem(5);
			}

			&:nth-child(2) {
				top: rem(11);
			}

			&:last-child {
				width: 70%;
				top: rem(17);
			}

			&--clicked {
				&:nth-child(1) {
					top: rem(5);
					right: 0;
					transform: rotate(45deg);
				}

				&:nth-child(2) {
					width: 0;
					opacity: 0;
				}

				&:nth-child(3) {
					top: rem(20);
					width: rem(22);
					right: 0;
					transform: rotate(-45deg);
				}
			}
		}
	}

	.hamburgerText {
		margin-right: rem(8);
		font-size: rem(18);
		font-weight: 300;
	}
}

.megaMenu {
	position: absolute;
	width: 100%;
	height: 0;
	opacity: 0;
	pointer-events: none;
	// visibility: hidden;
	transition: all 0.6s cubic-bezier(0.4, 0, 0.2, 1) 0ms;
	z-index: 1;

	&--opened {
		position: absolute;
		width: 100%;
		height: calc(100vh - rem(72));
		opacity: 1;
		transition: all 0.6s cubic-bezier(0.4, 0, 0.2, 1) 0ms;
		pointer-events: all;
		visibility: visible;
	}

	/* Change the width of the scrollbar */
	::-webkit-scrollbar {
		width: rem(4);
	}

	/* Change the background color of the scrollbar track */
	::-webkit-scrollbar-track {
		background: var(--bw-border-soft);
	}

	/* Change the color of the scrollbar thumb (the draggable part) */
	::-webkit-scrollbar-thumb {
		 background: var(--bw-foreground);
	}
}
