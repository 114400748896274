@use '../../../include' as *;

.contentSection {
	isolation: isolate;
	position: relative;
	display: flex;
	flex-direction: column-reverse;
	background-color: var(--bw-background);

	@media #{$lg} {
		flex-direction: row;
	}

	&.hero {
		--header-height: 66px;

		@media #{$lg} {
			--header-height: 82px;

			min-height: calc(100dvh - var(--header-height, 0px));

			.rightBackground {
				img {
					padding-top: 168px;
				}
			}
		}
	}

	&.big {
		@media #{$lg} {
			min-height: 80dvh;

			.rightBackground {
				img {
					padding-top: 88px;
				}
			}
		}
	}

	&.small {
		.textContentWrapper {
			&.solo {
				@media #{$lg} {
					padding: rem(40) rem(296) rem(40) rem(120);
				}
			}

			&.column {
				@media #{$lg} {
					padding: rem(48) rem(74) rem(48) rem(120);
				}
			}
		}

		.imageWrapper {
			&.fillLeft,
			&.fillRight {
				@media #{$lg} {
					padding: 0;
				}
			}

			&.fitLeft,
			&.fitRight {
				@media #{$lg} {
					padding: rem(48) 0;
				}
			}

			&.fillRight,
			&.fitRight {
				order: unset;

				@media #{$lg} {
					order: 1;
				}
			}
		}

		.sunburst {
			img {
				height: rem(256);
			}
		}
	}

	.textContentWrapper {
		max-width: rem(2560);
		position: relative;
		z-index: 10;
		padding: rem(48) rem(20);
		display: flex;
		flex-direction: column;
		justify-content: center;
		flex-grow: 1;

		&.column {
			@media #{$lg} {
				padding: rem(96) rem(74) rem(68) rem(120);
			}
		}

		&.solo {
			@media #{$lg} {
				flex-grow: 1;
				padding: rem(144) rem(296) rem(108) rem(120);
			}

			&:has(+ .sunburst) {
				@media #{$lg} {
					padding-right: rem(60);
				}
			}
		}

		&:has(.title p > b:first-child) .tagline {
			color: inherit;
		}

		.tagline {
			display: block;
			margin-bottom: rem(16);
			font-family: $secondary;
			font-size: rem(16);
			line-height: rem(20);
			font-weight: 800;
			letter-spacing: rem(2.4);
			text-transform: uppercase;
			color: var(--bw-text-accent);
		}

		.title {
			margin-bottom: rem(36);
			font-family: 'DM Sans', sans-serif;
			font-weight: 300;
			line-height: 100%;
			font-size: rem(40);
			word-wrap: break-word;

			@media #{$lg} {
				font-size: rem(48);
			}

			@media #{$lgn} {
				font-size: rem(60);
			}

			@media #{$xxl} {
				font-size: rem(64);
			}

			@media #{$vl} {
				font-size: rem(90);
			}

			&:last-child {
				margin-bottom: rem(0);
			}

			b {
				color: var(--bw-text-accent);
			}

			p + p {
				margin-top: rem(18);
			}

			* {
				font-family: inherit;
				font-weight: inherit;
				word-wrap: inherit;
				line-height: inherit;
				font-size: inherit;
			}
		}

		.text {
			display: flex;
			flex-direction: column;
			font-size: rem(18);
			margin-bottom: rem(22);

			@media #{$lg} {
				flex-direction: row;
				flex-wrap: wrap;

				gap: 0 rem(18);

				p {
					width: 100%;
				}
			}

			@media #{$vl} {
				font-size: rem(25);
			}

			&.accentBullets {
				&:has(ul:first-child) {
					@media #{$lg} {
						margin-top: rem(32);
					}
				}

				ul {
					font-size: rem(20);
					line-height: rem(28);

					@media #{$vl} {
						font-size: rem(28);
					}

					li {
						margin-bottom: rem(24);
						list-style-type: square;

						&::marker {
							line-height: 0;
							color: var(--bw-bright-yellow);
						}

						@media #{$lg} {
							margin-bottom: rem(44);
						}

						@media #{$vl} {
							margin-bottom: rem(60);
						}
					}
				}
			}

			&:last-child {
				margin-bottom: rem(0);
			}

			b {
				color: var(--bw-text-accent);
			}

			* {
				font-family: inherit;
				font-weight: inherit;
				word-wrap: inherit;
				line-height: inherit;
				font-size: inherit;
			}

			p {
				&:has(+ ul) {
					margin-bottom: rem(12);
				}
			}

			ul {
				flex-grow: 1;
				gap: rem(20);
				list-style-position: outside;

				@media #{$lg} {
					gap: rem(44);
				}

				li {
					list-style-position: inherit;
					list-style-type: disc;
					margin-left: 28px;

					p {
						display: inline;
					}
				}
			}

			img {
				width: 100%;
				max-width: rem(512);
				height: auto;

				@media #{$lg} {
					margin-top: rem(24);
					max-width: 100%;
					height: rem(320);
					width: fit-content;
					object-fit: cover;
				}
			}
		}

		.buttonsWrapper {
			display: flex;
			flex-wrap: wrap;
			gap: rem(12);

			@media #{$md-m} {
				flex-direction: column;
			}
		}
	}

	.imageWrapper {
		max-height: 100vh;

		@media #{$lg} {
			min-width: 48.5%;
			max-width: 48.5%;
		}

		&.fitLeft {
			@media #{$lg} {
				img {
					object-position: left;
				}
			}
		}

		&.fitRight {
			@media #{$lg} {
				img {
					object-position: right;
				}
			}
		}

		&.fillRight,
		&.fitRight,
		&.backgroundRight {
			order: 1;
		}

		&.fillLeft,
		&.fillRight {
			@media #{$lg} {
				img {
					object-position: center;
					object-fit: cover;
				}
			}
		}

		&.fitLeft,
		&.fitRight {
			@media #{$lg} {
				padding: rem(96) 0;

				img {
					object-position: center;
					object-fit: contain;
				}
			}
		}

		&.backgroundRight {
			@media #{$lg} {
				img {
					position: absolute;
					object-fit: scale-down;
					object-position: right bottom;
					width: 70%;
					right: 0;
					bottom: 0;
				}
			}
		}

		img {
			width: 100%;

			height: auto;
			display: block;
			object-position: center;

			@media #{$lg} {
				height: 100%;
				object-fit: cover;
			}
		}
	}

	.sunburst {
		display: none;

		@media #{$lg} {
			margin: rem(40) 0 rem(40);
			display: block;
		}

		img {
			height: rem(370);
			object-position: right;
			object-fit: contain;
		}
	}
}

.form {
	display: flex;
	align-items: center;
	margin: 0;
	padding: 0 rem(20) rem(48);

	@media #{$lg} {
		order: 0;
		padding: 0;
	}

	&.inTextContent {
		margin: 0;
	}

	&.standaloneColumn {
		order: -1;

		@media #{$lg} {
			order: 0;
			padding: rem(96) rem(96) rem(68) rem(24);
			min-width: 40%;
			max-width: 40%;
		}
	}
}
