@use '../../../styles/shared/include' as *;

.AgencyInsuranceTypeListSection {
	:global {
		.container {
			&--max {
				padding: 0;
			}
		}
	}
	.AgencyInsuranceTypeList {
		@media #{$lgn} {
			display: flex;
			flex-direction: row-reverse;
		}
		.textSection {
			padding: rem(48) rem(20);
			color: $deepBlue;
			display: flex;
			flex-direction: column;
			justify-content: center;
			@media #{$lgn} {
				width: 59%;
				padding: rem(112) rem(120);
			}
			width: 100%;

			h2 {
				font-size: rem(40);
				font-weight: 300;
				line-height: 100%;
				margin-bottom: rem(24);
				color: $deepBlue;
				@media #{$lgn} {
					font-size: rem(64);
				}

				& span {
					font-weight: 800;
					color: inherit;
				}
			}
			span {
				font-size: rem(20);
				font-weight: 500;
				line-height: 100%;
				margin-bottom: rem(16);
				display: block;
			}

			p {
				font-size: rem(18);
				font-weight: 500;
				line-height: 140%;
				padding-bottom: rem(48);
				color: $deepBlue;

				& span {
					font-weight: 800;
					color: inherit;
				}
			}
			.typeContainer {
				width: 100%;
				display: flex;
				flex-wrap: wrap;
				gap: rem(16);

				.types {
					font-size: rem(14);
					line-height: 140%;
					padding: rem(12);
					width: fit-content;
					display: flex;
					align-items: center;
					border: rem(1) solid $deepBlue;
					gap: rem(4);
					min-width: 100%;

					@media screen and (min-width: 769px) {
						min-width: calc((100% - (rem(16))) / 2);
					}
					@media screen and (min-width: 1360px) {
						min-width: calc((100% - (rem(32))) / 3);
					}
					svg {
						width: rem(32);
						height: rem(30);
					}
					&:hover {
						background: $deepBlue;
						color: $brightYellow;
						path {
							fill: $brightYellow !important;
						}
					}
				}
			}
		}
		.imageSection {
			height: auto;
			img {
				height: 100%;
				width: 100%;
				object-fit: cover;
			}

			@media #{$lgn} {
				width: 41%;
			}
		}
	}
}
