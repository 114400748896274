@use '../../../../styles/shared/include' as *;
.miniCtaBanner {
	background-color: $cream;
	.bannerContainer {
		margin: 0 auto;
		max-width: rem(1440);
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		padding: rem(48) rem(20);
		gap: rem(48);
		h4 {
			font-size: rem(20);
			line-height: 140%;
			font-size: rem(20);
			font-weight: 500;
			color: $deepBlue;

			i {
				font-weight: 300;
			}
			@media #{$xl} {
				h4 {
					font-size: rem(32);
				}
			}
		}

		.buttonContainer {
			@media #{$md} {
				display: flex;
				width: fit-content;
				flex-direction: row;
			}
		}
		@media #{$md} {
			padding: rem(48) rem(100);
			justify-content: center;
			h4 {
				font-size: rem(28);
			}
		}
		@media #{$xxl} {
			padding: rem(48) rem(141.5);
			h4 {
				font-size: rem(32);
			}
		}
	}
}
