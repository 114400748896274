/* styles/Button.module.scss */
@use '../../../styles/shared/include' as *;

.functionalityButton {
	display: inline-flex;
	width: 100%;
	padding: rem(16) rem(24);
	font-size: rem(20);
	font-weight: 500;
	line-height: rem(20);
	border-radius: rem(100);
	color: var(--text-color);
	background-color: var(--bg-color);
	border: 1px solid var(--border-color);
	cursor: pointer;
	text-align: center;
	transition: background-color 0.3s, color 0.3s;
	justify-content: center;
	svg {
		display: inline-block;
		margin-left: rem(8);
	}
	@media #{$md} {
		width: fit-content;
	}
	:global {
		font-family: $primary;
		.svg {
			&.is-icon-right {
				margin-left: 0;
				margin-right: rem(8);
			}
		}
	}
	&:hover {
		background-color: var(--text-color);
		color: var(--bg-color, $deepBlue);
		border: rem(1) solid var(--bg-color);

		path {
			fill: var(--bg-color, $deepBlue) !important;
		}
	}
}
