@use '../../../include' as *;

.button {
	background-color: var(--bw-background);
	border-width: 1px;
	text-wrap: nowrap;
	min-width: 120px;

	&.link {
		border-color: transparent;
		font-size: unset;
		font-weight: unset;
		line-height: unset;
		padding: unset;

		&:hover {
			background-color: var(--bw-background);
			color: var(--bw-foreground);
		}
	}

	&.hyperlink {
		min-width: auto;
		border: none;
		font-size: unset;
		font-weight: unset;
		line-height: unset;
		padding: unset;
		background-color: unset;
		text-decoration: underline;

		&:hover {
			background-color: unset;
			color: var(--bw-foreground);
		}
	}

	// invert foreground and background
	&:hover {
		background-color: var(--bw-foreground);
		color: var(--bw-background);
		border-color: var(--bw-background);
	}

	display: inline-block;
	font-size: rem(20);
	font-family: 'DM Sans', sans-serif;
	padding: rem(16);
	font-weight: 500;
	line-height: rem(20);
	border-radius: rem(100);
	cursor: pointer;
	text-align: center;
	transition:
		background-color 0.3s,
		color 0.3s;
}
