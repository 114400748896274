@use '../../styles/shared/include' as *;

.podcastCard {
	padding: rem(60) 0;
	@media #{$lg} {
		padding: rem(112) 0;
	}
	:global {
		.is__pageloader {
			position: fixed;
			top: 0;
			left: 0;
			width: 100vw;
			height: 100vh;
			display: flex;
			align-items: center;
			justify-content: center;
			background-color: $deepBlue;
			figure {
				width: rem(60);
				@media #{$lg} {
					width: rem(100);
				}
				img {
					width: 100%;
					height: auto;
				}
			}
		}
		.podcastCard__wrapper {
			display: flex;
			flex-wrap: wrap;
			gap: rem(30);
			justify-content: space-between;
			.podcastCard__latest {
				width: 100%;
				@media #{$lg} {
					width: calc((480 / 1200) * 100%);
					position: relative;
				}
				.podcastCard__latest-wrapper {
					@media #{$lg} {
						position: sticky;
						top: calc(rem(100) + var(--announcement-banner-height));
					}
				}
				.latest__card-img {
					width: rem(100);
					margin-bottom: rem(16);
					@media #{$md} {
						width: rem(150);
						margin-bottom: rem(20);
					}
					@media #{$lg} {
						width: rem(240);
						margin-bottom: rem(24);
					}
					img {
						width: 100%;
						height: auto;
					}
				}
				h1 {
					font-weight: 300;
					line-height: 100%;
					color: $deepBlue;
					font-size: rem(34);
					margin-bottom: rem(20);
					@media #{$lg} {
						font-size: rem(40);
						margin-bottom: rem(24);
					}
					@media #{$xxl} {
						font-size: rem(64);
					}
				}
				p {
					font-weight: 400;
					line-height: 140%;
					color: $deepBlue;
					font-size: rem(16);
					margin-bottom: rem(20);
					@media #{$lg} {
						font-weight: 500;
						font-size: rem(18);
						margin-bottom: rem(24);
					}
				}
				.play__latest {
					display: inline-flex;
					align-items: center;
					gap: rem(8);
					padding: rem(12) rem(20);
					color: $deepBlue;
					font-weight: 500;
					line-height: 100%;
					font-size: rem(16);
					border-radius: rem(100);
					background-color: $brightYellow;
					margin-bottom: rem(20);
					@media #{$lg} {
						padding: rem(16) rem(24);
						font-size: rem(20);
						margin-bottom: rem(24);
					}
					cursor: pointer;
					.svg {
						width: rem(16);
						height: rem(16);
						@media #{$lg} {
							width: rem(20);
							height: rem(20);
						}
					}
				}
				.podcast__subscribe {
					display: flex;
					align-items: center;
					flex-wrap: wrap;
					@media #{$xl} {
						padding-left: rem(32);
					}
					p {
						font-weight: 300;
						color: $deepBlue;
						line-height: 100%;
						margin-bottom: 0;
						margin-right: rem(20);
						font-size: rem(16);
						@media #{$lg} {
							margin-right: rem(24);
							font-size: rem(20);
						}
					}
					ul {
						display: flex;
						align-items: center;
						gap: rem(5);
						@media #{$lg} {
							gap: rem(8);
						}
						li {
							a {
								width: rem(36);
								@media #{$lg} {
									width: rem(50);
								}
								img {
									width: 100%;
									height: auto;
								}
							}
						}
					}
				}
				.pocast__artist {
					display: flex;
					align-items: center;
					flex-wrap: wrap;
					margin-top: rem(30);
					gap: rem(16);
					@media #{$lg} {
						margin-top: rem(48);
						gap: rem(21);
					}
					figure {
						width: rem(80);
						height: auto;
						border-radius: 50%;
						overflow: hidden;
						@media #{$lg} {
							width: rem(120);
						}
						img {
							width: 100%;
							height: auto;
						}
					}
					article {
						width: calc(100% - 98px);
						@media #{$lg} {
							width: calc(100% - 141px);
						}
						span {
							display: block;
							font-weight: 500;
							line-height: 140%;
							color: $deepBlue;
							font-size: rem(16);
							margin-bottom: 0;
							@media #{$lg} {
								font-size: rem(18);
							}
						}
						p {
							font-weight: 300;
							line-height: 140%;
							color: $deepBlue;
							font-size: rem(16);
							margin-bottom: 0;
							@media #{$lg} {
								font-size: rem(18);
							}
						}
					}
				}
			}
			.podcastCard__listing {
				width: 100%;
				margin-top: rem(50);
				@media #{$lg} {
					margin-top: 0;
					width: calc((600 / 1200) * 100%);
				}
				.listing_header {
					font-weight: 300;
					line-height: 100%;
					color: $deepBlue;
					margin-bottom: 0;
					font-size: rem(22);
					padding-bottom: rem(24);
					border-bottom: rem(1) solid $periwinkleGrey;
					@media #{$lg} {
						font-size: rem(28);
					}
				}
				.podcastCard__item {
					padding: rem(24) 0;
					border-bottom: rem(1) solid $periwinkleGrey;
					.episod__time {
						font-weight: 400;
						line-height: 100%;
						color: $deepBlue;
						font-size: rem(14);
						margin-bottom: rem(8);
					}
					.episod__title {
						font-weight: 500;
						line-height: 120%;
						color: $deepBlue;
						font-size: rem(28);
						margin-bottom: rem(8);
					}
					.episod__dicription {
						font-weight: 400;
						line-height: 140%;
						color: $deepBlue;
						font-size: rem(16);
						margin-bottom: 0;
					}
					.episod__btn {
						display: inline-flex;
						align-items: center;
						border-radius: rem(100);
						font-weight: 500;
						line-height: 111.11%;
						color: $deepBlue;
						background-color: transparent;
						border: rem(1) solid $periwinkleGrey;
						text-transform: capitalize;
						cursor: pointer;
						margin-top: rem(20);
						padding: rem(10) rem(18);
						font-size: rem(16);
						@include transition();
						@media #{$lg} {
							margin-top: rem(23);
							padding: rem(12) rem(20);
							font-size: rem(18);
						}
						img {
							width: rem(16);
							height: rem(16);
							margin-right: rem(8);
							@media #{$lg} {
								width: rem(20);
								height: rem(20);
							}
						}
						span {
							font-weight: 500;
							line-height: 142%;
							color: $deepBlue;
							font-size: rem(14);
							margin-left: rem(10);
							@media #{$lg} {
								margin-left: rem(16);
							}
						}
						&:hover {
							background-color: $brightYellow;
							border-color: $brightYellow;
						}
					}
				}
			}
		}
	}
}
