@use '../../styles/shared/include' as *;

.PodcastBanner {
	background-color: $deepBlue;
	padding: rem(60) 0;
	@media #{$md} {
		padding: rem(112) 0;
	}
	.loader {
		display: flex;
		justify-content: center;
	}
	:global {
		.PodcastBanner__row {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			.PodcastBanner__img {
				width: 100%;
				margin-bottom: rem(30);
				@media #{$md} {
					margin-bottom: 0;
					order: 2;
					width: calc((240 / 1200) * 100%);
				}
				figure {
					width: rem(180);
					max-width: 100%;
					@media #{$md} {
						width: rem(240);
					}
					img {
						width: 100%;
						height: auto;
					}
				}
			}
			.PodcastBanner__content {
				width: 100%;
				display: flex;
				flex-direction: column;
				gap: rem(18);
				@media #{$md} {
					order: 1;
					width: calc((840 / 1200) * 100%);
					gap: rem(24);
				}
				.headingTagline {
					display: block;
					color: $brightYellow;
					font-family: $secondary;
					line-height: 125%;
					letter-spacing: rem(2.4);
					text-transform: uppercase;
					font-weight: 800;
					font-size: rem(16);
					margin: 0;
				}
				h2 {
					color: $white;
					line-height: 100%;
					font-weight: 300;
					margin: 0;
					font-size: rem(36);
					@media #{$md} {
						font-size: rem(64);
					}
				}
				p {
					color: $white;
					line-height: 140%;
					font-weight: 500;
					font-size: rem(16);
					margin: 0;
					@media #{$md} {
						font-size: rem(18);
					}
					& + p {
						margin-top: rem(20);
					}
				}
				.PodcastBanner__buttons {
					display: inline-flex;
					flex-wrap: wrap;
					gap: rem(8);
				}
			}
		}
	}
}
