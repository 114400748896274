@use '../../styles/shared/include' as *;

.PersonCardSliderSection {
	padding: rem(60) 0;
	background-color: $periwinkleGrey;
	overflow: hidden;
	@media #{$lg} {
		padding: rem(112) 0;
	}
	:global {
		.PersonCardSliderSection__header {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			margin-bottom: rem(20);
			@media #{$lg} {
				margin-bottom: rem(32);
			}
			article {
				width: calc((960 / 1200) * 100%);
				h2 {
					color: $deepBlue;
					line-height: 100%;
					font-weight: 300;
					font-size: rem(34);
					margin-bottom: rem(30);
					@media #{$lg} {
						font-size: rem(64);
						margin-bottom: rem(60);
					}
				}
				.slider__arrow {
					display: inline-flex;
					align-items: center;
					justify-content: space-between;
					padding: rem(0) rem(6);
					border-radius: rem(100);
					background-color: rgba(255, 255, 255, 0.3);
					width: rem(211.7);
					span {
						width: rem(40);
						padding: rem(12);
						background-color: unset;
						img {
							width: 100%;
							height: auto;
						}
						&.prev__arrow {
							transform: scaleX(-1);
						}
					}
					p {
						color: $deepBlue;
						font-weight: 500;
						line-height: 125%;
						font-size: rem(16);
						padding: rem(8) 0;
						margin: 0;
					}
				}
			}
			figure {
				width: calc((107 / 1200) * 100%);
				position: relative;
				@media #{$xl} {
					top: rem(24);
					right: rem(22);
				}
				img {
					width: 100%;
					height: auto;
				}
			}
		}
		.PersonCardSliderSection__wrapper {
			width: auto;
			margin: 0 rem(-10);
			@media #{$xl} {
				margin: 0 rem(-20);
			}
			.slick-list {
				margin: 0 rem(20);
				@media #{$md-1} {
					margin: 0 rem(30);
				}
				@media #{$lgn} {
					margin: 0;
				}
			}
			.swiper-slide {
				margin: 0 rem(20);
				display: flex;
				cursor: grab;
				.slick-slide {
					padding: 0 rem(10);
					@media #{$xl} {
						padding: 0 rem(20);
					}
				}
				@media #{$md-1} {
					margin: 0 rem(30);
				}
				@media #{$lgn} {
					margin: 0;
				}
			}
			.slick-track {
				display: flex;
				cursor: grab;
				.slick-slide {
					padding: 0 rem(10);
					@media #{$xl} {
						padding: 0 rem(20);
					}
				}
			}
			.PersonCardSliderSection__item {
				border-radius: rem(12);
				display: flex;
				flex-direction: column;
				background-color: $white;
				position: relative;
				width: 100%;
				padding: rem(20) rem(30) rem(30);
				@media #{$xl} {
					padding: rem(24) rem(40) rem(40);
				}
				.logo__layer {
					position: absolute;
					top: rem(30);
					width: rem(30);
					right: 0;
					@media #{$xl} {
						top: rem(44);
						width: rem(46);
					}
					img {
						width: 100%;
						height: auto;
					}
				}
				.slider__photo {
					border-radius: 50%;
					overflow: hidden;
					width: rem(100);
					height: rem(100);
					margin-bottom: rem(15);
					@media #{$xl} {
						width: rem(150);
						height: rem(150);
						margin-bottom: rem(24);
					}
					img {
						width: 100%;
						height: 100%;
						object-fit: cover;
						-webkit-filter: grayscale(100%);
						filter: grayscale(100%);
					}
				}
				.testimonial-name {
					font-weight: 300;
					color: $deepBlue;
					font-size: rem(20);
					line-height: 100%;
					margin-bottom: rem(4);
				}
				.testimonial-designation {
					font-weight: 600;
					color: $deepBlue;
					font-size: rem(16);
					line-height: 140%;
					margin-bottom: rem(16);
				}
				p {
					font-weight: 500;
					color: $deepBlue;
					font-size: rem(16);
					line-height: 140%;
					margin-bottom: 0;
				}
			}
		}
	}
}
