@use '../../../../include' as *;

.megaMenuContentsContainer {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
  background-color: var(--bw-background);
  position: absolute;
  width: 100%;
  overflow: hidden;

  .menuMobileHeading {
    padding: rem(72) rem(20) rem(24);
    color: var(--bw-foreground);
    position: relative;

    h5 {
      font-size: rem(24);
      font-weight: 300;
      line-height: 100%;
    }

    @media (orientation: landscape) and (max-height: 420px) {
      padding-block: rem(20);
      display: flex;
      flex-direction: row;
      gap: 2rem;
    }

    .backBtn {
      display: flex;
      align-items: center;
      gap: rem(8);
      color: var(--bw-foreground);
      background-color: transparent;
      padding: 0.5rem 0.5rem 1rem 0;

      // prevent the distracting flash of yellow when you click
      :active {
        button {
          color: var(--bw-foreground);
        }
      }

      svg {
        height: rem(16);
        width: rem(16);
      }

      cursor: pointer;
      font-size: rem(16);
      font-weight: 300;
      line-height: rem(16);
    }
  }

  .subMenuMobileContent {
    display: flex;
    justify-content: space-between;
    font-size: rem(18);
    font-weight: 300;
    border-bottom: rem(1) solid var(--bw-border-soft);

    &:first-child {
      border-top: rem(1) solid var(--bw-border-soft);
    }

    svg {
      width: rem(15);
      height: rem(15);
    }

    .menuItemLink {
      width: 100%;

      a {
        width: 100%;
        padding: rem(20);
      }

      button {
        width: 100%;
        text-align: left;
        font-weight: unset;
      }

      &:hover {
        color: var(--bw-text-accent);
      }
    }

    .menuItemDropdown {
      width: 100%;
      padding: rem(20);
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;

      &:hover {
        --bw-foreground: var(--bw-text-accent)
      }
    }
  }

  .subMenuList {
    flex: 1;
    overflow-y: auto;
  }

  .menuMobileContentWrapper {
    overflow-y: auto;
    overflow-x: hidden;
    color: var(--bw-foreground);
    flex: 1;
  }

  .menu-list-mobile {
    a, button {
      text-align: left;
      border: none;
      color: var(--bw-foreground);
      font-size: rem(18);
      padding: 1.25rem;
      font-weight: 300;
      
      &:hover {
        color: var(--bw-text-accent);
        background: transparent;
      }
    }
  }

  .subMenuMobileContentWrapper {
    background-color: var(--bw-background);
    width: 100%;
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    pointer-events: none;
    overflow-x: hidden;
    color: var(--bw-foreground);
    transform: translateX(100%);
    pointer-events: all;
    display: flex;
    flex-direction: column;

    .subMenuItemFooter {
      width: 100%;
      display: flex;
      font-size: rem(16);
      font-weight: 300;
      color: var(--bw-foreground);
      justify-content: space-evenly;

      a {
        padding: rem(8);

        &:hover {
          --bw-foreground: var(--bw-text-accent)
        }
      }
    }

    transition-property: transform;
    transform: translate(100%);
    transition-duration: 0.2s;

    &--isActive {
      overflow-y: auto;
      pointer-events: all;
      transform: translate(0);
    }
  }

  .mobileButtonWrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: rem(8);
    padding: 0 rem(20) rem(20) rem(20);
    margin: rem(14) 0;


    a {
      border: solid 1px;
    }

    @media #{$lgn} {
      display: none;
    }

    @media (orientation: landscape) and (max-height: 420px) {
      flex-direction: row;
      padding-bottom: 0;
    }
  }

  .menuActionButtonsContainer {
    a, button {
      padding: 0.5rem 1rem;
      border: solid 1px;
      font-size: 1.1rem;
    }
  }
}