@use '../../../include' as *;

.comparisonTable {
	position: relative;
	padding: 3rem rem(60);
	background-color: var(--bw-background);
	overflow-x: hidden;

	& :global .container--max {
		padding: 0 rem(20);

		@media #{$lg} {
			padding: 0 rem(32);
		}
	}

	&:global(.soft-primary) {
		.titleColumn {
			// apply Deep blue
			--bw-foreground: var(--bw-primary-500);
			--bw-text-accent: var(--bw-secondary-500);

			h2 {
				--bw-foreground: var(--bw-primary-500);
				text-transform: uppercase;
				font-weight: 700;
				min-width: max-content;
			}

			.head {
				--bw-background: unset;
				padding-left: 0;

				&::after {
					--bw-foreground: var(--bw-primary-500);
				}
			}
		}

		.head {
			--bw-foreground: var(--bw-white);
			--bw-background: var(--bw-primary-500);
			--bw-text-accent: var(--bw-secondary-500);

			h4 {
				--bw-foreground: var(--bw-secondary-500);
			}
		}
	}

	ul {
		display: flex;

		@media #{$md-m} {
			flex-direction: column;
		}

		gap: 1vw;

		@media #{$lg} {
			margin: 0;
		}

		li {
			flex: 1;
			margin-bottom: rem(20);

			@media #{$md} {
				margin-bottom: 0;
			}

			&.titleColumn {
				@media #{$md-m} {
					display: none;
				}

				flex: 0;
				--bw-foreground: var(--bw-text-accent);
				// text-wrap: nowrap;

				.row {
					padding-left: 0;
				}

				.head {
					display: flex;
					flex-direction: column;
					gap: rem(32);
				}

				.row::after,
				.head::after {
					content: '';
					position: absolute;
					bottom: 0;
					left: -100rem;
					right: 0;
					width: 1000rem;
					border-bottom: rem(1) solid var(--bw-foreground);
					z-index: 1;
				}
			}

			.head {
				position: relative;
				padding: rem(30) rem(20);
				background-color: var(--bw-background);
				border-top-left-radius: rem(12);
				border-top-right-radius: rem(12);

				@media #{$md} {
					padding: rem(30) rem(12);
					min-height: rem(192);
				}

				@media #{$lg} {
					min-height: rem(256);
					padding: rem(40) rem(20);
				}

				@media #{$xxl} {
					padding: rem(40);
				}

				h3 {
					font-weight: 400;
					line-height: 120%;
					font-size: rem(18);
					margin-bottom: rem(20);

					@media #{$md} {
						min-height: rem(50);
					}

					@media #{$lgn} {
						margin-bottom: rem(24);
						min-height: rem(58);
						font-size: rem(24);
					}
				}

				h4 {
					font-weight: 300;
					line-height: 100%;
					font-size: rem(26);
					margin-bottom: rem(4);

					@media #{$lg} {
						font-size: rem(38);
					}

					@media #{$lgn} {
						font-size: rem(50);
					}
				}

				p {
					font-weight: 400;
					line-height: 100%;
					font-size: rem(16);
					margin-bottom: 0;
				}

				@media #{$md-m} {
					border-bottom: solid 1px;
				}
			}

			.row {
				position: relative;
				background-color: var(--bw-background);
				padding-inline: rem(20);
				display: flex;
				flex-direction: column;
				justify-content: center;
				height: rem(80);
				font-size: rem(18);
				font-weight: 400;
				line-height: 140%;

				@media #{$md-m} {
					border-bottom: solid 1px;
				}

				@media #{$md} {
					font-size: 1.7vw;
				}

				@media #{$lgn} {
					min-height: rem(114);
				}

				@media #{$xxl} {
					padding: rem(40);
				}

				.title {
					font-weight: 600;
					line-height: 120%;
					font-size: rem(16);
					margin-bottom: rem(6);
					display: block;

					@media #{$md} {
						display: none;
					}
				}

				ul {
					padding-left: rem(30);
					display: block;
					margin: 0;

					@media #{$lgn} {
						padding-left: rem(20);
					}

					@media #{$xxl} {
						padding-left: rem(29);
					}

					li,
					li span {
						list-style-type: disc;
						font-weight: 400;
						line-height: 161%;
						margin: 0;
						font-size: rem(16);
						padding: 0;

						@media #{$lgn} {
							font-size: rem(18);
						}
					}
				}

				&:last-child {
					border-bottom-left-radius: rem(12);
					border-bottom-right-radius: rem(12);
					height: fit-content;
					padding-block: rem(20);

					&::after {
						display: none;
					}
				}
			}

			&.title {
				.row {
					background-color: transparent;
					padding: 0;
					display: none;

					@media #{$lg} {
						display: flex;
					}

					span {
						font-weight: 400;
						line-height: 130%;
						font-size: rem(16);
						margin: 0;

						@media #{$lgn} {
							font-weight: 600;
							line-height: 161%;
							font-size: rem(18);
						}
					}
				}
			}
		}
	}
}
