@use '../../../styles/shared/include' as *;

.footerContainer {
	background-color: $deepBlue;
	color: $white;
	display: flex;
	flex-direction: column;
	padding: rem(48) rem(20);

	padding-right: rem(20);
	@media #{$md} {
		padding: rem(64) rem(31.6) rem(32) rem(20);
		justify-content: space-between;
	}
	@media #{$lg} {
		flex-direction: row;
		padding-left: rem(0);
	}
	.footerLogo {
		position: relative;
		width: rem(109);
		height: rem(164);

		img {
			width: 100%;
			height: rem(100);
			position: absolute;
			left: rem(-20);
			@media #{$md} {
				height: auto;
			}
			@media #{$lg} {
				width: rem(206);
				height: rem(189);
				top: 0;
				left: unset;
			}
		}
		@media #{$lg} {
			flex-basis: 30%;
			width: 30%;

			height: auto;
		}
		@media #{$lgn} {
			flex-basis: 33.33%;
			width: 33.33%;
			min-width: rem(480);
		}
	}
	.footerTextSection {
		display: flex;
		flex-direction: column;
		align-content: space-between;

		@media #{$md} {
			padding-top: rem(0);
		}
		@media #{$lgn} {
			width: 66.67%;
			flex-basis: 66.67%;
		}

		.footerTop {
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			span {
				display: block;
				text-transform: uppercase;
				font-family: $secondary;
				padding-bottom: rem(8);
				color: $brightYellow;
				font-size: rem(12);
				font-weight: 800;
				line-height: rem(20);
				letter-spacing: rem(1.8);
			}
			.listItems {
				display: flex;
				flex-wrap: wrap;
				height: auto;
				width: 100%;
				ul {
					padding-bottom: rem(20);
					font-weight: 400;
					line-height: 140%;
					li {
						padding-bottom: rem(8);
					}
				}
				.footerContent {
					min-width: 50%;
				}
			}
			.footerContentSection {
				display: flex;
				flex-direction: row;
				flex-wrap: wrap;

				width: 100%;
				justify-content: space-between;
			}
			.formContainer {
				margin-bottom: rem(47);
				form {
					display: flex;
					flex-direction: column;
					gap: 5px;
					@media #{$xl} {
						gap: 0;
					}
					input {
						color: $periwinkleGrey;
						font-size: rem(16);
						font-style: normal;
						font-weight: 500;
						line-height: rem(20);
						width: rem(330);
						padding: rem(12) rem(24);
						border-radius: rem(3);
						+ input {
							margin-top: rem(8);
						}
					}
					.buttonContainer {
						padding-top: rem(15);
						a {
							padding: rem(11) rem(24);
						}
					}
				}
			}
			.socialContainer {
				a {
					margin-right: rem(16);
					&:hover {
						path {
							fill: $brightYellow !important;
						}
					}
				}
			}
			@media #{$md} {
				flex-direction: row;
			}
			@media #{$lgn} {
				margin-bottom: rem(64);
			}
			@media #{$xxl} {
				margin-bottom: rem(106);
			}
		}
		.footerBottom {
			margin-top: rem(48);
			@media #{$md} {
				.linksCopyrightContainer {
					display: flex;
					margin-bottom: rem(8);
				}
				margin-top: rem(20);
			}
			@media #{$lgn} {
				margin-top: unset;
			}
			.footerCopyright.captchaNotice {
				font-size: rem(12.8);
				opacity: 0.75;
			}
			.footerCopyright {
				font-size: rem(16);
				padding-right: rem(24);
				font-weight: 400;
				line-height: 140%;
				a {
					text-decoration: underline;
					&:hover {
						color: $brightYellow;
					}
				}
			}
			.footerLinks {
				display: flex;
				color: $white;
				font-size: rem(16);
				li {
					position: relative;
					margin-right: rem(5);
				}

				li:not(:last-child)::after {
					content: '|';
					margin: 0 5px;
				}

				flex-wrap: wrap;
			}
		}
		a {
			&:hover {
				color: $brightYellow;
			}
		}
	}
}
