@use '../../styles/shared/include' as *;

.PodcastPlayer {
	position: fixed;
	z-index: 9;
	border-radius: rem(16);
	background-color: #ececec;
	bottom: rem(10);
	right: rem(10);
	padding: rem(10);
	max-width: calc(100% - 20px);
	@media #{$lg} {
		bottom: rem(16);
		right: rem(16);
		padding: rem(16);
	}
	:global {
		.PodcastPlayer__wrapper {
			display: flex;
			align-items: flex-start;
			justify-content: space-between;
			gap: rem(10);
			@media #{$lg} {
				gap: rem(16);
			}
			figure {
				flex-shrink: 0;
				border-radius: rem(4);
				overflow: hidden;
				background-color: $periwinkleGrey;
				width: rem(40);
				@media #{$lg} {
					width: rem(50);
				}
				img {
					width: 100%;
					height: auto;
					object-fit: contain;
					object-position: center;
				}
			}
			.player__title {
				max-width: calc(100% - 100px);
				min-width: 100px;
				h2 {
					display: -webkit-box;
					width: 483px;
					max-width: 100%;
					-webkit-box-orient: vertical;
					-webkit-line-clamp: 1;
					line-clamp: 1;
					text-overflow: ellipsis;
					overflow: hidden;
					color: $black;
					line-height: 100%;
					font-weight: 500;
					font-size: rem(16);
					margin-bottom: rem(6);
				}
				p {
					color: rgba(0, 0, 0, 0.6);
					line-height: 100%;
					font-weight: 500;
					font-size: rem(14);
					@media #{$lg} {
						font-size: rem(16);
					}
				}
			}
			.player__play-pause {
				width: rem(36);
				align-self: center;
				cursor: pointer;
				background: transparent;
				flex-shrink: 0;
				@media #{$lg} {
					width: rem(46);
				}
				svg {
					width: 100%;
					height: auto;
				}
			}
			.close-btn {
				background: transparent;
				display: flex;
				gap: rem(4);
				align-items: flex-start;
				color: $black;
				font-size: rem(14);
				font-weight: 500;
				line-height: 140%;
				cursor: pointer;
				span {
					display: none;
					@media #{$lgn} {
						display: block;
					}
				}
				svg {
					@media #{$lgn} {
						margin-top: rem(2);
					}
				}
			}
		}
		.audio__player {
			margin-top: rem(10);
			width: 100%;
			@media #{$lg} {
				margin-top: rem(18);
			}
			audio {
				width: 100%;
			}
		}
	}
}
