@use '../../../include' as *;

.accordion {
	display: flex;
	flex-direction: column;
	padding: rem(64) rem(26) rem(48);

	@media #{$lg} {
		padding: rem(124) rem(136) rem(124) rem(124);
	}

	.title {
		width: fit-content;
		font-weight: 300;
		font-size: rem(64);
		line-height: rem(64);

		margin-bottom: rem(40);
	}
}
