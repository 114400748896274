@use '../../../include' as *;

.gridItem {
	display: flex;
	flex-direction: column;
	gap: rem(20);
	flex: 1;

	@media #{$lg} {
		gap: rem(32);
	}

	&:hover {
		.text {
			u {
				background-size: 100% 100%;
			}
		}
	}

	.imageWrapper {
		width: fit-content;
		padding: rem(16);
		background-color: var(--bw-bright-yellow);
	}

	.title {
		p {
			font-size: rem(28);
			font-weight: 300;
			line-height: 100%;

			b {
				font-size: rem(28);
				font-weight: 300;
				line-height: 100%;
				color: var(--bw-text-accent);
			}
		}
	}

	.text {
		u {
			text-decoration: none;
			background-image: linear-gradient(
				var(--bw-text-accent),
				var(--bw-text-accent)
			);
			background-position: 0 calc(100% - rem(1));
			background-repeat: no-repeat;
			background-size: 0 100%;
			transition: 0.35s ease;
		}
	}
}
